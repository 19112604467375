import './App.css';
import Foot from './Foot';
import Crousal from './Crousal';
import About from './About';
import Hero from './Hero';


function App() {
  return (
    <div className="App">
      <Crousal />
      <div className='divider' ></div>
      <Hero/>
      <div className='divider' ></div>
      <About/>
      <Foot />
    </div>
  );
}

export default App;
