import React from 'react'

const Hero = () => {
    const GoWhatsapp = () => {
        window.open('https://wa.link/zdfius', '_blank');
      }
    return (
        <div className='HeroSec' >
            <h1 className='heroHeading' >Elevate the Thrill: Bet Smart, Win Bigger!</h1>
            <h5 className='heroDesc' >Bet smartly with us, and the rewards will be bigger than ever. We prioritize responsible gaming, ensuring that the thrill of the game is accompanied by a secure and enjoyable experience. Whether you're a seasoned player or new to the world of betting, our platform is designed to cater to your needs, offering a seamless journey where every bet is a step towards a thrilling victory.</h5>
            <div className='AboutImg cp' onClick={GoWhatsapp} ><img src="55.jpeg" alt="" /></div>
            <h3 className='heroDesc' >Join us in redefining the betting experience. Elevate the thrill, bet smart, and win bigger at Iron Exchange</h3>
        </div>
    )
}

export default Hero